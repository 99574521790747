function dialogify(message, input_options) {

    var visible_dialogs = jQuery('.ui-dialog:visible').length;

    if (visible_dialogs < 1) {

        try {

            var dialog = jQuery("<div>" + message + "</div>");

            var classes = {};

            var default_classes = {
                "ui-dialog": "dialog",
                "ui-dialog-titlebar": "dialog__titlebar",
                "ui-dialog-buttonpane": "dialog__buttons",
                "ui-dialog-content": "dialog__text",
                "ui-dialog-buttonset": "dialog__buttons-inner"
            };

            if (typeof input_options != 'undefined' && typeof input_options.classes != 'undefined' && input_options.classes) {
                var input_classes = input_options.classes;
                delete input_options.classes;
                classes = jQuery.extend(default_classes, input_classes);
            } else {
                classes = default_classes;
            }

            var options = {
                maxHeight: 480,
                width: 720,
                classes: classes,
                show: {effect: 'fade', duration: 250},
                hide: {effect: 'fade', duration: 250},
                closeText: "hide",
                draggable: true,
                resizable: false,
                responsive: true,
                clickOut: false,
                autoOpen: true,
                modal: true,
                buttons: {
                    'OK': function () {
                        jQuery(this).dialog("close");
                    }
                },
                close: function () {
                    jQuery(this).remove();
                },
                open: function () {
                    jQuery('.dialog__text').scrollTop(0);
                    setTimeout(function () {
                        jQuery('.dialog__text').scrollTop(0);
                    }, 200);

                    setTimeout(function () {
                        jQuery('.dialog__text').scrollTop(0);
                    }, 300);

                    setTimeout(function () {
                        jQuery('.dialog__text').scrollTop(0);
                    }, 400);

                    setTimeout(function () {
                        jQuery('.dialog__text').scrollTop(0);
                    }, 500);

                    if (jQuery('.js-search-filter').length > 0) {
                        enableAutocomplete('.js-search-filter');
                    }
                }
            };

            if (typeof input_options != 'undefined' && input_options) {
                jQuery.extend(options, input_options);
            }

            jQuery(dialog).dialog(options);
        } catch (e) {
            console.log(e);
        }
    }
}
