jQuery(document).ready(function ($) {

    // Function to handle form submission
    function handleFormSubmission(form) {
        console.log('handleFormSubmission()');
        var email = form.find('.js-klaviyo-email').val();

        // Simple email validation
        var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email === '' || !emailRegex.test(email)) {
            dialogify('Please enter a valid email address.');
            return;
        }

        $.ajax({
            url: '/wp-admin/admin-ajax.php',  // WordPress AJAX URL
            type: 'POST',
            data: {
                action: 'klaviyo_subscribe',  // The action for WordPress hook
                email: email
            },
            success: function (response) {
                var message = response.success ? 'You have successfully subscribed!' : response.data;
                dialogify(message);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error('AJAX Error Details:');
                console.error('Status:', textStatus);
                console.error('Error Thrown:', errorThrown);
                console.error('Response Text:', jqXHR.responseText);

                // Optionally display detailed error to the user
                // dialogify(`An error occurred: ${textStatus} - ${errorThrown}`);

                // Keep user-friendly message
                dialogify('An error occurred. Please try again.');
            }
        });
    }

    // Click event handler for the sign-up button
    $('.js-klaviyo-sign-up').on('click', function () {
        console.log('click');
        var form = $(this).closest('.js-klaviyo-form');
        handleFormSubmission(form);
    });

    // Keydown event handler for the email input to detect Enter key
    $('.js-klaviyo-email').on('keydown', function (e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            e.preventDefault(); // Prevent the default form submission
            var form = $(this).closest('.js-klaviyo-form');
            console.log('Enter key pressed. Submitting form:', form);
            handleFormSubmission(form);
        }
    });

});
