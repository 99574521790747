/*
jQuery(document).ready(async function($) {
    let customerLocation = localStorage.getItem("customerLocation") ?? '';

    if (customerLocation === '') {
        await $.ajax({
            url: get_gift_card.ajax_url,
            type: 'POST',
            async: true,
            data: {
                action: 'get_gift_card',
                nonce: get_gift_card.nonce
            },
            complete: function(response) {
                if (response) {
                    let data = response.responseText;
                    console.log(data);
                    console.log(response);

                    if (data === 'GB') {
                        $('body').addClass('location-' + data);
                        localStorage.setItem("customerLocation", data);
                    } else if (data === '') {
                        $('body').addClass('location-unknown');
                    } else {
                        $('body').addClass('location-INT');
                        localStorage.setItem("customerLocation", "INT");
                    }
                }
            }
        });
    } else {
        $('body').addClass('location-' + customerLocation);
    }
});
 */